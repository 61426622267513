/* eslint-disable @typescript-eslint/no-explicit-any */
import { endEventDate } from '../constants';
import { IEvent } from '../interfaces/api/tickets.interface';

const filterService = {
  sortFilter: function (list: any[], sortBy: string, reverse = false): IEvent[] {
    if (list.length === 0) return list;

    try {
      switch (typeof list[0][sortBy]) {
        case 'string':
          list.sort((a: any, b: any) => {
            const aString = a[sortBy].toLowerCase();
            const bString = b[sortBy].toLowerCase();

            if (aString < bString) {
              return -1;
            }
            if (aString > bString) {
              return 1;
            }
            return 0;
          });
          break;
        case 'number':
          list.sort((a: any, b: any) => {
            if (a[sortBy] === Infinity) {
              return 1;
            } else {
              return a[sortBy] - b[sortBy];
            }
          });
          break;
        default:
          throwErr(`${sortBy} label isn't possible to sort`);
      }

      if (!reverse) {
        return list;
      } else {
        return list.reverse();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return list;
    }
  },

  searchFilter: function (list: any[], searchText: string, searchBy: string) {
    if (list.length === 0) return list;

    try {
      if (typeof list[0][searchBy] != 'string') {
        throwErr(`${searchBy} label isn't string type and isn't possible to search`);
      }

      searchText = searchText.toLowerCase();
      const searchedList: any[] = [];

      list.forEach(item => {
        if (item[searchBy].toLowerCase().includes(searchText)) {
          searchedList.push(item);
        }
      });
      return searchedList;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return list;
    }
  },

  showFilter: function (list: any[], showBy: string, reverse = false) {
    try {
      if (typeof list[0][showBy] != 'boolean') {
        throwErr(`${showBy} label isn't boolean type and isn't possible to show`);
      }

      const resultList: any[] = [];
      list.forEach(item => {
        if (item[showBy] === !reverse) {
          resultList.push(item);
        }
      });

      return resultList;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return list;
    }
  },

  // TODO for startdate and enddate for events with Date type
  filterByDate: function (list: IEvent[], startDate: Date | null, endDate: Date | null) {
    let result = list;
    endDate?.setHours(23, 59, 59);
    if (endDate === null && startDate) {
      result = result.filter(event => {
        if (event.date === 'anytime') return true;
        if (event.date === 'period') return true;
        return new Date(event.date) >= startDate;
      });
      return result;
    }

    if (startDate === null && endDate) {
      result = result.filter(event => {
        if (event.date === 'anytime') return true;
        return new Date(event.date || '25.05.2023') <= endDate;
      });
      return result;
    }

    if (startDate && endDate) {
      result = result.filter(event => {
        if (event.date === 'anytime') return true;
        if (event.date === 'period') {
          const endEvent = new Date(endEventDate);
          return endEvent >= startDate;
        }
        const eventDate = new Date(event.date);
        return eventDate >= startDate && eventDate <= endDate;
      });
      return result;
    }

    return result;
  },
};

function throwErr(msg: string): never {
  throw new Error(msg);
}

export default filterService;
