import Page from '../../common/components/Page/Page';
import ResetPasswordForm from './components/ResetPasswordForm';

export default function ResetPassword() {
  const cardClasses = 'col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-4';
  return (
    <Page>
      <div className="container-fluid position-relative">
        <div className="row">
          <h1>Resetează parola</h1>
        </div>
        <div className="row d-flex justify-content-center">
          <div className={`${cardClasses} mt-md-3`}>
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    </Page>
  );
}
