import { CustomForm } from '../../../common/components/MoftForm/CustomForm';
import { login } from '../../../common/services/api/auth';
import { IUserLogin } from '../../../common/interfaces/api/auth.interface';
import { REQUIRED } from '../../../common/constants';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function LoginForm() {
  const nav = useNavigate();
  const defaultErrorMessage = 'Nu te-ai putut loga. Te rugăm încearcă mai târziu';
  type ErrorMessages = {
    [key: string]: string;
  };

  const errorMessages: ErrorMessages = {
    'Your account is not activated yet. Please check your email.':
      'Contul tău nu este încă activat. Te rugăm verifică-ți email-ul!',
    'Wrong email or password': 'Email sau parolă greșită!',
    'Account not found! Please register first.': 
      'Contul nu a fost găsit! Te rugăm să te înregistrezi mai întâi'
  };

  return (
    <CustomForm
      title="AUTENTIFICARE"
      fields={[
        {
          name: 'email',
          initialValue: '',
          type: 'email',
          placeholder: 'E-mail',
          validation: Yup.string()
            .email('Introdu o adresă de email validă')
            .matches(RegExp('.+@(stud\\.[a-z]{3,6}\\.)?(upb|lsacbucuresti)\\.ro$'), 'Introdu adresa de student')
            .max(255, 'Introdu un email de maxim 255 de caractere')
            .required(REQUIRED),
        },
        {
          name: 'password',
          initialValue: '',
          type: 'password',
          placeholder: 'Parola',
          validation: Yup.string()
            .max(255, 'Introdu o parolă de maxim 8 caractere')
            .required(REQUIRED),
        },
      ]}
      submitButtonText="Login"
      onSubmit={async (values: IUserLogin) => {
        try {
          const { data } = await login(values);
          // success message
          localStorage.setItem('token', data.token);

          const response = 'Te-ai logat cu succes';
          nav('/tickets');
          Swal.fire({
            title: response,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          });
        } catch (e: any) {
          // Error message
          let response = defaultErrorMessage;
          if (e.response.data.message && errorMessages[e.response.data.message]) {
            response = errorMessages[e.response.data.message];
          }

          Swal.fire({
            title: response,
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
          });

          throw e;
        }
      }}
    />
  );
}
