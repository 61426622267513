import { ContactForm } from './ContactForm';
import ContactIMG from './ContactIMG.png';
import './contact.scss';

export default function Contact() {
  const cardClasses =
    'col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 col-xxl-4 card border-0 bg-transparent ';
  return (
    <div className="row my-3 align-items-center justify-content-center ">
      <div className={cardClasses}>
        <img src={ContactIMG} className="image card-img-top" alt="Contact" />
      </div>
      <div className="col-10 my-5 col-sm-10 col-md-5 col-lg-5 col-xl-5 col-xxl-4 offset-lg-1 offset-md-1 text-center">
        <ContactForm />
      </div>
    </div>
  );
}
