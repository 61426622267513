import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import './App.css';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Verify from './pages/Verify/Verify';
import Tickets from './pages/Tickets/Tickets';
import Recommendations from './pages/Recommendations/Recommendations';
import Page404 from './pages/Page404/Page404';
import Quiz from './pages/Quiz/Quiz';
import Calendar from './pages/Calendar/Calendar';
import Placeholder from './pages/Placeholder/Placeholder';
import Team from './pages/Team/Team';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/checkAccount/:token" element={<Verify />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/recommendations" element={<Recommendations />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/*" element={<Page404 />} />
          <Route path="/team" element={<Team />} />
          <Route path="/404" element={<Page404 />} />
        </Routes>
      </BrowserRouter>

      {/* to uncomment the above lines when the event starts */}

      {/* <Placeholder /> */}
    </div>
  );
}

export default App;
