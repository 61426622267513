import axios from 'axios';
import { API_URL } from '../../constants';

const instance = axios.create({
  baseURL: API_URL,
});

export function getSponsors(type: 'sponsor' | 'institution') {
  return instance.get('/sponsors/' + type);
}
