import Page from '../../common/components/Page/Page';

export default function Page404() {
  return (
    <Page>
      <div className="container vh-100 d-flex justify-content-center align-items-center">
        <div className="">
          <img className="img-fluid" src="/logo.png" />
          <p className="h1">404 Nu există această pagină</p>
        </div>
      </div>
    </Page>
  );
}
