import axios from 'axios';
import { API_URL } from '../../constants';

const instance = axios.create({
  baseURL: API_URL,
});

export interface IContactRequest {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export function contactRequest(data: IContactRequest) {
  return instance.post('contacts', data);
}
