import Carousel from 'react-bootstrap/Carousel';
import { ISponsor } from '../../../../../common/interfaces/api/sponsors.interface';
import CarouselSlideComponent from './CarouselSlide';
import './Carousel.scss';
// import styles from './Carousel.module.css';

interface CarouselProps {
  sponsors: ISponsor[];
  itemsPerSlide: number;
}

const CarouselComponent: React.FC<CarouselProps> = ({ sponsors, itemsPerSlide }) => {
  let carouselSlides: any[] = [];

  for (let i = 0; i < sponsors.length; i += itemsPerSlide) {
    carouselSlides = [
      ...carouselSlides,
      <CarouselSlideComponent
        key={i}
        sponsors={sponsors}
        itemsPerSlide={itemsPerSlide}
        startIndex={i}
      />,
    ];
  }

  return (
    <Carousel className="carousel-container" variant="dark" indicators={false} interval={3000}>
      {carouselSlides.map((Slide, index) => (
        <Carousel.Item key={index}>{Slide}</Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
