import axios from 'axios';
import { API_URL } from '../../constants';
import { hasToken } from './auth';

const instance = axios.create({
  baseURL: API_URL,
});

export function getRecommendations(category: string) {
  return instance.get(`events/recommendations`, {
    headers: { Authorization: `Bearer ${hasToken()}` },
  });
}

export function getEvents(category: string) {
  return instance.get(`events?category=${category}`, {
    headers: { Authorization: `Bearer ${hasToken()}` },
  });
}

export function getEventsAndOwned() {
  return instance.get(`events/calendar`, {
    headers: { Authorization: `Bearer ${hasToken()}` },
  });
}

export function createMyTicket(eventId: number) {
  return instance.post(
    'tickets',
    { eventId },
    { headers: { Authorization: `Bearer ${hasToken()}` } },
  );
}

export function deleteMyTicket(id: number) {
  return instance.delete(`tickets/my-tickets/${id}`, {
    headers: { Authorization: `Bearer ${hasToken()}` },
  });
}

export function getMyTickets() {
  return instance.get('tickets', {
    headers: { Authorization: `Bearer ${hasToken()}` },
  });
}
