import axios from 'axios';
import { API_URL } from '../../constants';
import {
  IUserForgot,
  IUserLogin,
  IUserLoginReceived,
  IUserRegister,
  IUserReset,
  //IUserVerify,
} from '../../interfaces/api/auth.interface';

const instance = axios.create({
  baseURL: API_URL,
});

export function register(data: IUserRegister) {
  return instance.post('/auth/register', data);
}

export function login(data: IUserLogin) {
  return instance.post<IUserLoginReceived>('/auth/login', data);
}

export function forgot(data: IUserForgot) {
  return instance.post('/auth/forgot-password', data);
}

export function reset(data: IUserReset) {
  return instance.post(`/auth/reset-password/${data.token}`, data);
}

export function verify(token: string) {
  return instance.post(`/auth/checkAccount`, { token });
}

export function hasToken(): string | null {
  return localStorage.getItem('token');
}
