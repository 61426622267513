import Page from '../../common/components/Page/Page';
import RecommendationsComponent from './components/RecommendationsComponent';
import { useEffect, useState } from 'react';
import { getRecommendations } from '../../common/services/api/tickets';
import { IEvent } from '../../common/interfaces/api/tickets.interface';

export default function Recommendations() {
  const [recommendations, setRecommendations] = useState<IEvent[]>([]);

  useEffect(() => {
    getRecommendations('RECOMMENDATION').then(r => {
      setRecommendations(r.data);
    });
  }, []);
  return (
    <Page>
      <RecommendationsComponent recommendations={recommendations} />
    </Page>
  );
}
