interface IProps {
  message: string;
  customClass?: string;
}

export default function ErrorMessage({ message, customClass }: IProps) {
  return (
    <h4
      className={`text-success fw-bolder ${customClass}`}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
}
