import { Calendar as BigCalendar, CalendarProps, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './Calendar.scss';
import 'moment/locale/ro';
import { useState, useEffect } from 'react';
import ErrorMessage from '../../../common/components/ErrorMessage/ErrorMessage';
import { Spinner } from 'react-bootstrap';
import { getEventsAndOwned } from '../../../common/services/api/tickets';
import EventModal from '../../../pages/Tickets/components/EventModal';
import { IEvent } from '../../../common/interfaces/api/tickets.interface';
import { useNavigate } from 'react-router-dom';

moment.locale('ro');
const localizer = momentLocalizer(moment);

// This ensures weekdays will not be abreviated
const formats = {
  weekdayFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, 'dddd', culture),
};

// Changing the toolbar messages to romaninan
const message = {
  next: 'Înainte',
  previous: 'Înapoi',
  today: 'Azi',
  month: 'Lună',
  week: 'Săptămână',
  day: 'Zi',
  date: 'Data',
  time: 'Ora',
  event: 'Eveniment',
};

const event_colors: any = {
  MUSEUM: '#966443',
  OPERA: '#9E8472',
  MOVIE: '#6D8387',
  THEATRE: '#838562',
  EXTRA: '#588764',
  WORKSHOP: '#7C5969',
  PODCAST: '#8E6F60',
  BOOKED: '#760F13',
};

export default function CalendarComponent() {
  const nav = useNavigate();
  const errorMessage = `„Ai puțintică răbdare!”<br/>&nbsp &nbsp &nbsp &nbsp &nbsp &nbsp—Trahanache`;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [events, setEvents] = useState<IEvent[] & { booked?: boolean }>([]);
  const [clickedEvent, setClickedEvent] = useState<IEvent & { booked?: boolean }>();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setIsLoading(true);

    getEventsAndOwned()
      .then(response => {
        setEvents(
          response.data.map((event: any) => {
              event.start = moment(event.start,"DD.MM.YYYY, HH:mm").toDate();
              event.end = moment(event.start,"DD.MM.YYYY, HH:mm").toDate();
              event.end.setHours(event.end.getHours() + 1);
            return event;
          }),
        );

        setIsError(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
        nav('/logout');
      });
  }, []);
  return (
    <>
      {!isLoading ? (
        !isError ? (
          <BigCalendar
            localizer={localizer}
            formats={formats}
            events={events}
            messages={message}
            showAllEvents={true}
            onDoubleClickEvent={event => {
              setClickedEvent(event);
              handleShow();
            }}
            eventPropGetter={event => {
              const newStyle = {
                padding: '2px',
                backgroundColor: event_colors[event.category],
                borderRadius: '10px',
                border: '1px solid white',
              };
              newStyle.backgroundColor = event_colors[event.category];
              if (event.booked) {
                newStyle.backgroundColor = event_colors['BOOKED']
              }
              return {
                className: '',
                style: newStyle,
              };
            }}
          />
        ) : (
          <ErrorMessage message={errorMessage} customClass="background-black" />
        )
      ) : (
        <Spinner style={{ margin: 'auto', marginTop: '' }} animation="border" />
      )}
      {clickedEvent && (
        <EventModal
          event={clickedEvent}
          logo={clickedEvent.imgUrl}
          onHide={handleClose}
          show={show}
        />
      )}
    </>
  );
}
