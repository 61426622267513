import { CustomForm } from '../../../../common/components/MoftForm/CustomForm';
import { contactRequest } from '../../../../common/services/api/contact';
import { IContactRequest } from '../../../../common/interfaces/api/contact.interface';
import { REQUIRED } from '../../../../common/constants';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

export const ContactForm = () => {
  return (
    <CustomForm
      title="CONTACT"
      fields={[
        {
          name: 'name',
          initialValue: '',
          type: 'text',
          placeholder: 'Nume Prenume',
          validation: Yup.string()
            .max(255, 'Introdu un nume de maxim 255 de caractere')
            .required(REQUIRED),
        },
        {
          name: 'email',
          initialValue: '',
          type: 'email',
          placeholder: 'E-mail',
          validation: Yup.string()
            .email('Introdu o adresă de email validă')
            .max(255, 'Introdu un email de maxim 255 de caractere')
            .required(REQUIRED),
        },
        {
          name: 'message',
          initialValue: '',
          type: 'text',
          placeholder: 'Mesaj',
          validation: Yup.string()
            .max(1000, 'Introdu un mesaj de maxim 1000 caractere')
            .required(REQUIRED),
        },
      ]}
      submitButtonText="TRIMITE"
      onSubmit={async (values: IContactRequest) => {
        try {
          await contactRequest(values);

          // success message
          const response = 'Mesaj trimis cu succes';

          // empty ContactForm
          values.name = '';
          values.email = '';
          values.message = '';

          Swal.fire({
            title: response,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          });
        } catch (e) {
          // Error message
          const response = 'Mesajul nu s-a putut trimite';

          Swal.fire({
            title: response,
            icon: 'error',
            timer: 5000,
            showConfirmButton: false,
          });

          throw e;
        }
      }}
    />
  );
};
