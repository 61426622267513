import Page from '../../common/components/Page/Page';
import './components/Calendar.scss';
import CalendarComponent from './components/CalendarComponent';

export default function Calendar() {
  const cardClasses1 =
    'col-10 mb-sm-5 col-sm-9 col-md-6  col-lg-5 col-xl-5 col-xxl-4 card border-0 bg-transparent ';
  return (
    <Page>
      {/* Temporary height, is required by the component so it doesn't collapse  */}
      <div className="calendar-div" style={{ height: '90vh' }}>
        <CalendarComponent />
      </div>
    </Page>
  );
}
