import styles from './PartnerItem.module.scss';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CONTENT_URL } from '../../../../common/constants';
import PlaceholderImage from '../../../../common/assets/podcast-placeholder.png';
import { PartnerModal } from './PartnerModal';
import { ISponsor } from '../../../../common/interfaces/api/sponsors.interface';

interface PartenerItemProps {
  sponsor: ISponsor;
  type: 'static' | 'carousel';
}

export default function PartnerItem({ sponsor, type }: PartenerItemProps) {
  const cardClasses1 = 'col-sm-12 border-0' + (type == 'carousel' ? ' col-md-4' : ' col-md-3');
  const { logoUrl } = sponsor;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const partnerLogo = logoUrl;
  return (
    <>
      <div className={`${cardClasses1} mb-3 mt-3 px-lg-5 bg-transparent`}>
        <div className="row g-0">
          <div
            className={`col-12 m-auto p-2 border border-3 border-dark ${
              type == 'carousel' ? styles.img_container_carousel : styles.img_container_static
            } bg-transparent`}
          >
            {/* <img src={partnerLogo} className="img-fluid" alt="..." onClick={handleShow} /> */}
            {logoUrl ? (
              <img src={partnerLogo} className="img-fluid  mh-100" alt="..." onClick={handleShow} />
            ) : (
              <img
                src={PlaceholderImage}
                className="img-fluid mh-100"
                alt="..."
                onClick={handleShow}
              />
            )}
          </div>
        </div>
      </div>

      <PartnerModal sponsor={sponsor} buttonText="Detalii" onHide={handleClose} show={show} />
    </>
  );
}
