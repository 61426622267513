export interface IEventCard {
  event: IEvent;
  extraChecks?: IExtraChecks;
  ticket?: ITicket;
  is_recommedation?: boolean;
}

export enum CategoryEnum {
  MUSEUM = 'MUSEUM',
  OPERA = 'OPERA',
  MOVIE = 'MOVIE',
  THEATRE = 'THEATRE',
  EXTRA = 'EXTRA',
  RECOMMENDATION = 'RECOMMENDATION',
}
export enum CatetgoryUIEnum {
  MUSEUM = 'Muzeu',
  OPERA = 'Opera',
  MOVIE = 'FIlm',
  THEATRE = 'Teatru',
  EXTRA = 'Extra',
  RECOMMENDATION = 'Recomandare',
}

export interface IExtraChecks {
  occupiedDates: { [key: string]: IEvent }; // date to event
  ticketsPerCategory: { [key: string]: number };
}

export interface IEvent {
  id: number;
  name: string;
  description: string;
  ticketType: string;
  imgUrl: string;
  maxTickets: number;
  availableTickets: number;
  category: CategoryEnum;
  isReleased: boolean;
  release: number;
  date: string;
  booked?: boolean;
  tickets: object[];
  link?: string;
  is_limited: boolean;
}
// export interface IEvent {
//   id: number;
//   name: string;
//   invitation_type: string;
//   date: string;
//   rule: string;
//   tickets: string;
// }

export interface ITicket {
  id: number;
  status: 'BOOKED' | 'IN_QUEUE';
  event?: IEvent;
}
