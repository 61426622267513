import { useState, useEffect, forwardRef } from 'react';
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { hasToken } from '../../common/services/api/auth';
import { getEvents, getMyTickets } from '../../common/services/api/tickets';
import Page from '../../common/components/Page/Page';
import EventCard from './components/EventCard';
import DatePicker from 'react-datepicker';
import ro from 'date-fns/locale/ro';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './components/Tickets.module.scss';
import filterService from '../../common/services/filters.service';
import {
  IExtraChecks,
  IEvent,
  ITicket,
  CategoryEnum,
} from '../../common/interfaces/api/tickets.interface';
import cloneDeep from 'lodash.clonedeep';
import { useCookies } from 'react-cookie';

interface IFilters {
  searchInput: string;
  sortOption: string;
}

export default function Tickets() {
  const [cookie, setCookie] = useCookies(['category']);
  // Events
  const [events, setEvents] = useState<IEvent[]>([]);
  const [category, setCategory] = useState(cookie.category || 'MUSEUM');

  // data for filters
  const [rawEvents, setRawEvents] = useState<IEvent[]>([]);

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Search Input
  const [searchInput, setSearchInput] = useState<string>('');

  // Sort Input
  const [sortOption, setSortOption] = useState<string>('default');

  // Styling
  const tabStyle = `mx-3 tab border-0`;

  // Others
  const nav = useNavigate();

  // Filters
  const [myFilters, setMyFilters] = useState<IFilters>({ searchInput, sortOption });

  const [extraChecksTickets, setExtraChecksTickets] = useState<ITicket[]>([]);
  const [extraChecks, setExtraChecks] = useState<IExtraChecks>({
    occupiedDates: {},
    ticketsPerCategory: {},
  });

  // Fetch data
  useEffect(() => {
    setIsLoading(true);
    if (category !== 'my_tickets') {
      getEvents(category)
        .then(r => {
          setRawEvents(r.data.events);
          setEvents(r.data.events);
          filters(r.data.events);
          setExtraChecksTickets(r.data.tickets);
          setIsLoading(false);
        })
        .catch(e => {
          setEvents([]);
          setExtraChecksTickets([]);
          nav('/logout');
          setIsLoading(false);
        });
    } else {
      getMyTickets()
        .then(r => {
          setEvents(r.data);
          setExtraChecksTickets([]);
          setIsLoading(false);
        })
        .catch(e => {
          setEvents([]);
          setExtraChecksTickets([]);
          nav('/logout');
          setIsLoading(false);
        });
    }
  }, [category]);

  useEffect(() => {
    const new_checks: IExtraChecks = { occupiedDates: {}, ticketsPerCategory: {} };
    extraChecksTickets.forEach(t => {
      if (!t.event) return;
      //hardcoded i know but give me a break pls (will fix this later)
      if(t.event.date.length > 10)
        new_checks.occupiedDates[t.event.date.slice(0, 10)] = t.event;

      const key = `${t.event.category}${t.event.release}`;
      if (!new_checks.ticketsPerCategory[key]) new_checks.ticketsPerCategory[key] = 0;

      new_checks.ticketsPerCategory[key] += 1;
    });

    setExtraChecks(new_checks);
  }, [events]);

  // Filters change
  useEffect(() => {
    filters(cloneDeep(rawEvents));
  }, [myFilters]);

  // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <input
      className={`${styles.calendarInput} background-grey`}
      onClick={onClick}
      ref={ref}
      value={value}
    ></input>
  ));

  function filters(data: IEvent[]) {
    data = filterService.searchFilter(data, searchInput, 'name');

    if (myFilters.sortOption !== 'default') {
      const reverse = myFilters.sortOption !== 'ascending';
      data = filterService.sortFilter(data, 'availableTickets', reverse);
    }
    setEvents(data);
    return;
  }

  return (
    <Page>
      <div className="container">
        <p className="fw-bolder mb-4 mt-3 color-primary h1">Bilete</p>
        <Tabs
          activeKey={category}
          onSelect={selectCategory => {
            if (selectCategory !== category) setEvents([]);
            if (selectCategory) {
              setCookie('category', selectCategory);
              setCategory(selectCategory);
            }
          }}
          className="d-flex justify-content-center border-0"
        >
          {hasToken() != undefined ? (
            <Tab tabClassName={tabStyle} eventKey="my_tickets" title="Biletele Mele" />
          ) : null}
          <Tab tabClassName={tabStyle} eventKey="MUSEUM" title="Muzeu" />
          <Tab tabClassName={tabStyle} eventKey="OPERA" title="Operă" />
          <Tab tabClassName={tabStyle} eventKey="MOVIE" title="Film" />
          <Tab tabClassName={tabStyle} eventKey="THEATRE" title="Teatru" />
          <Tab tabClassName={tabStyle} eventKey="WORKSHOP" title="Workshop" />
          <Tab tabClassName={tabStyle} eventKey="EXTRA" title="Extra" />
        </Tabs>
        <div className={`d-flex justify-content-center mt-3 ${styles.filtersMenu}`}>
          <div className={`mx-2 mb-sm-0 mb-4 ${styles.filtersMenu1} d-flex align-items-center`}>
            <p className="h6">Căutare</p>
            <div className={`input-group background-grey ${styles.searchGroupInput}`}>
              <input
                type="text"
                placeholder="Caută eveniment"
                onChange={e => {
                  setSearchInput(e.target.value);
                  myFilters.searchInput = e.target.value;
                  setMyFilters(cloneDeep(myFilters));
                }}
                className={`form-control background-grey ${styles.searchInput}`}
              />
              <i className="mx-1 bi bi-search" />
            </div>
          </div>
          <div className={`mx-2 mb-sm-0 mb-2 ${styles.filtersMenu1} d-flex align-items-center`}>
            <p className="h6">Sortare număr bilete</p>
            <select
              className={`form-select background-grey ${styles.sortInput}`}
              defaultValue={'default'}
              onChange={e => {
                setSortOption(e.target.value);
                myFilters.sortOption = e.target.value;
                setMyFilters(cloneDeep(myFilters));
              }}
            >
              <option value="default">Implicit</option>
              <option value="ascending">Crescător</option>
              <option selected value="descending">
                Decrescător
              </option>
            </select>
          </div>
        </div>

        <div className="m-auto mt-4 px-sm-0 px-3">
          <h4>
            Utilizatorii au dreptul la câte{' '}
            <b style={{ color: '#ee0000' }}>
              2 bilete din fiecare categorie în fiecare tură de bilete
            </b>
            .
          </h4>
          <h5>
            Evenimentele care au numărul de bilete nelimitat nu sunt luate în considerare în cadrul
            acestei restricții.
          </h5>
          <h5>
            Utilizatorii au dreptul <b style={{ color: '#ee0000' }}>la un spectacol pe zi.</b>
          </h5>
        </div>
        <>
          {events.length === 0 ? (
            <p className="m-auto text-danger h4 mt-5">
              Momentan nu ați rezervat bilete la niciunul dintre evenimentele disponibile!
            </p>
          ) : isLoading ? (
            <Spinner style={{ margin: 'auto', marginTop: '120px' }} animation="border" />
          ) : (
            <div className="row justify-content-center my-5 ">
              {events.map((event: any, index) => {
                if (category !== 'my_tickets')
                  return <EventCard event={event} extraChecks={extraChecks} key={index}/>;
                else return <EventCard event={event.event} ticket={event} key={index}/>;
              })}
            </div>
          )}
        </>
      </div>
    </Page>
  );
}
