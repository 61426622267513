import axios from 'axios';
import { API_URL } from '../../constants';
import { hasToken } from './auth';
import { IQuiz } from '../../interfaces/api/quiz.interface';

const instance = axios.create({
  baseURL: API_URL,
});

export async function fetchQuiz() {
  return await instance.get('quiz/user', {
    headers: { Authorization: `Bearer ${hasToken()}` },
  });
}

export async function submitQuiz(quiz: IQuiz) {
  const answers = quiz.questions.map(question => ({
    questionId: question.id,
    selectedOptions: question.selectedAnswer?.id,
  }));

  console.log(answers);

  return await instance.post(
    'quiz/user',
    {
      answers: answers,
    },
    {
      headers: { Authorization: `Bearer ${hasToken()}` },
    },
  );
}
