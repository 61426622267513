import PartnerItem from '../PartnerItem';
import { ISponsor } from '../../../../../common/interfaces/api/sponsors.interface';

interface CarouselSlideProps {
  sponsors: ISponsor[];
  itemsPerSlide: number;
  startIndex: number;
}

const CarouselSlideComponent: React.FC<CarouselSlideProps> = ({
  sponsors,
  itemsPerSlide,
  startIndex,
}) => {
  const slideSponsors = sponsors.slice(startIndex, startIndex + itemsPerSlide);

  return (
    <div className="item-container row justify-content-start">
      {slideSponsors.map((sponsor: ISponsor) => (
        <PartnerItem key={sponsor.id} sponsor={sponsor} type="carousel" />
      ))}
    </div>
  );
};

export default CarouselSlideComponent;
