import { Modal } from 'react-bootstrap';
import MoftButton from '../../../../common/components/MoftButton/MoftButton';
import { CONTENT_URL } from '../../../../common/constants';
import { IPodcast } from '../../../../common/interfaces/api/podcats.interface';
import styles from '../PodcastList/PodcastModal.module.scss';

import PlaceholderImage from '../../../../common/assets/podcast-placeholder.png';
import { ISponsor } from '../../../../common/interfaces/api/sponsors.interface';

interface IProps {
  sponsor: ISponsor;
  onHide(): void;
  onClick?(): void;
  show: boolean;
  buttonText: string;
}

export function PartnerModal(props: IProps) {
  const { sponsor, show, onHide } = props;
  return (
    <>
      <Modal show={show} onHide={onHide} centered contentClassName={`${styles.podcastModal}`}>
        <Modal.Header className={`align-items-start ${styles.podcastModalHeader}`}>
          <div className="d-flex flex-column m-auto w-100">
            <Modal.Title className="fs-4 fw-bolder text-center">{sponsor.name}</Modal.Title>
            <div className={`${styles.imageContainer}`}>
              {sponsor.logoUrl ? (
                <img className={`m-auto ${styles.img}`} src={sponsor.logoUrl} alt="Podcast image" />
              ) : (
                <img
                  className={`m-auto ${styles.img}`}
                  src={PlaceholderImage}
                  alt="Podcast image"
                />
              )}
            </div>
          </div>

          <button
            type="button"
            className={`btn-close ${styles.modalCloseBtn}`}
            aria-label="Close"
            onClick={onHide}
          ></button>
        </Modal.Header>
        {sponsor.description ? (
          <Modal.Body className={`py-1 h6 ${styles.textContainer}`}>
            <p className="fs-5 fw-bolder text-center">Descriere: </p>
            <p dangerouslySetInnerHTML={{ __html: sponsor.description }} />
          </Modal.Body>
        ) : (
          <></>
        )}
        <Modal.Footer className={`m-auto p-0 ${styles.modalFooter}`}>
          {sponsor.link ? (
            <a href={sponsor.link} target="_blank" rel="noreferrer">
              <MoftButton text={props.buttonText} type={'button'} />
            </a>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
