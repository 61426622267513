import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import { IPodcast } from '../../../../common/interfaces/api/podcats.interface';
import { getPodcasts } from '../../../../common/services/api/podcasts';
import PodcastItem from './PodcastItem';

import styles from './PodcastList.module.scss';

export default function PodcastList() {
  const errorMessage = `„Ai puțintică răbdare!”<br/>&nbsp &nbsp &nbsp &nbsp &nbsp &nbsp—Trahanache`;

  const [podcasts, setPodcasts] = useState<IPodcast[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getPodcasts()
      .then(response => {
        setPodcasts(response.data);
        setIsError(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <div className="row">
        <h1 className="mt-1">Podcast-uri</h1>
      </div>
      {!isLoading ? (
        !isError && podcasts.length !== 0 ? (
          <div className={`row justify-content-start p-3 ${styles.podcastList}`}>
            {podcasts.map((podcast: IPodcast, index) => (
              <PodcastItem {...podcast} key={index} />
            ))}
          </div>
        ) : (
          <ErrorMessage message={errorMessage} customClass="background-black" />
        )
      ) : (
        <Spinner style={{ margin: 'auto', marginTop: '' }} animation="border" />
      )}
    </>
  );
}
