import React from 'react';
import './Team.scss';
import Page from '../../common/components/Page/Page';
import { Card } from 'react-bootstrap';

const teamData = [
  { name: 'Vîlcea Ștefania', position: 'Manager Proiect', image: '/org_team/poza-organizare4.jpg' },
  { name: 'Florea Daria', position: 'Vice Responsabil', image: '/org_team/poza-organizare1.jpg' },
  { name: 'Fedeleș Ana', position: 'Manager Design', image: '/org_team/poza-organizare8.jpg' },
  { name: 'Tunaru Ștefania', position: 'Manager FR', image: '/org_team/poza-organizare2.jpg' },
  { name: 'Spirchez Mihai', position: 'Manager HR', image: '/org_team/poza-organizare7.jpg' },
  { name: 'Achim Rareș', position: 'Manager IT', image: '/org_team/poza-organizare10.jpg' },
  { name: 'Popa Răzvan', position: 'Manager Logistică', image: '/org_team/poza-organizare6.jpg' },
  {
    name: 'Teodorescu Teodora',
    position: 'Manager Multimedia',
    image: '/org_team/poza-organizare9.jpg',
  },
  { name: 'Tudor Ștefania', position: 'Manager PR', image: '/org_team/poza-organizare3.jpg' },
  { name: 'Constantin Rareș', position: 'Mentor', image: '/org_team/poza-organizare13.jpg' },
  { name: 'Constantinescu Iulia', position: 'Mentor', image: '/org_team/poza-organizare12.jpg' },
  { name: 'Popescu Alexandra', position: 'Mentor', image: '/org_team/poza-organizare5.jpg' },
  { name: 'Radu Cristina', position: 'Mentor', image: '/org_team/poza-organizare11.jpg' },
];

export default function Team() {
  return (
    <Page>
      <div className="containerAll">
        <p className="titleEchipa">ECHIPA DE ORGANIZARE</p>

        <div className="imageRow">
          {teamData.map((member, index) => (
            <Card key={index} className="containerImage">
              <img className="teamImages" src={member.image} alt={member.name} />
              <p className="name">{member.name}</p>
              <p className="positionInTeam">{member.position}</p>
            </Card>
          ))}
        </div>
      </div>
    </Page>
  );
}
