import { Modal } from 'react-bootstrap';
import MoftButton from '../../../../common/components/MoftButton/MoftButton';
import { CONTENT_URL } from '../../../../common/constants';
import { IPodcast } from '../../../../common/interfaces/api/podcats.interface';
import styles from './PodcastModal.module.scss';

import PlaceholderImage from '../../../../common/assets/podcast-placeholder.png';

interface IProps {
  podcast: IPodcast;
  onHide(): void;
  onClick?(): void;
  show: boolean;
}

export function PodcastModal(props: IProps) {
  const { podcast, show, onHide } = props;
  //console.log(podcast.imgUrl);
  return (
    <>
      <Modal show={show} onHide={onHide} centered contentClassName={`${styles.podcastModal}`}>
        <Modal.Header className={`align-items-start ${styles.podcastModalHeader}`}>
          <div className="d-flex flex-column m-auto w-100">
            <Modal.Title className="fs-4 fw-bolder text-center">{podcast.name}</Modal.Title>
            <div className={`${styles.imageContainer}`}>
              {podcast.imgUrl ? (
                <img className={`m-auto ${styles.img}`} src={podcast.imgUrl} alt="Podcast image" />
              ) : (
                <img
                  className={`m-auto ${styles.img}`}
                  src={PlaceholderImage}
                  alt="Podcast image"
                />
              )}
            </div>
          </div>

          <button
            type="button"
            className={`btn-close ${styles.modalCloseBtn}`}
            aria-label="Close"
            onClick={onHide}></button>
        </Modal.Header>
        {podcast.description ? (
          <Modal.Body className={`py-1 h6 ${styles.textContainer}`}>
            <p className="fs-5 fw-bolder text-center">Descriere: </p>
            <p dangerouslySetInnerHTML={{ __html: podcast.description }} />
              <a href={podcast.link} target="_blank" rel="noreferrer">
                <MoftButton text={'Vizionează podcast'} type={'button'} />
              </a>
          </Modal.Body>
        ) : (
          <></>
        )}
        <Modal.Footer className={`m-auto p-0 ${styles.modalFooter}`}>
          {podcast.link ? (
            <a href={podcast.link} target="_blank" rel="noreferrer">
              <MoftButton text={'Vizionează podcast'} type={'button'} />
            </a>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
