import Page from '../../common/components/Page/Page';
import LoginForm from './components/LoginForm';
import ilustrationIMG from './components/ilustrationIMG.png';
import './components/Login.scss';
import { NavLink } from 'react-router-dom';

export default function Login() {
  const cardClasses1 =
    'col-10 col-sm-8 col-md-6 col-lg-5 col-xl-5 col-xxl-4 card border-0 bg-transparent ';
  return (
    <Page>
      <div className="row align-items-center justify-content-center offset">
        <div className={`${cardClasses1} imgContainer`}>
          <img src={ilustrationIMG} alt="Login" />
        </div>
        <div className="col-10 col-sm-10 col-md-6 col-lg-5 col-xl-5 col-xxl-4 offset-lg-1 text-center my-5 px-5">
          <LoginForm />
          <NavLink style={{ color: 'black', textDecoration: 'none' }} to="/forgot-password">
            <em>Am uitat parola</em>
          </NavLink>
        </div>
      </div>
    </Page>
  );
}
