import styles from './MoftButton.module.scss';

interface IButtonProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type: 'button' | 'submit' | 'reset' | undefined;
}

export default function MoftButton({ text, onClick, type }: IButtonProps) {
  return (
    <button onClick={onClick} className={`${styles.btn}`} type={type ? type : 'button'}>
      <p className={`h3 m-0 ${styles.text}`}>{text}</p>
    </button>
  );
}
