import MoftButton from '../../../../common/components/MoftButton/MoftButton';
import AboutIMG from './AboutIMG.png';
import './About.scss';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function About() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 75);
    }
  }, []);

  const nav = useNavigate();
  return (
    <div className="about-container">
      <h1 className="display-3 textTitlePhone">M.O.F.T. II 2024</h1>
      <div className="imageContainer">
        <div className="testTest">
          <img src={AboutIMG} className="aboutImage" />
        </div>
        <div className="aboutButton mobile-btn">
          <MoftButton text={'REZERVĂ BILETE'} onClick={() => nav('/tickets')} type="button" />
        </div>
      </div>
      <div className="titleBox border border-dark">
        <div className="aboutTextBox">
          <h1 className="display-3 textTitle">DESPRE EVENIMENT</h1>
        </div>
        <p className="textDescription">
          Arta și cultura pot reprezenta un refugiu de tot ce presupune cotidian, așadar LSAC
          București revine toamna aceasta în sprijinul studenților cu cel mai așteptat eveniment
          cultural, M.O.F.T. (Muzee. Operă. Film. Teatru.), ajuns la ediția 25! Semestrul acesta,
          evenimentul se desfășoară în perioada 25 martie - 25 aprilie și aduce o mulțime de
          noutăți, precum: workshop-uri în diverse domenii, seară de film, podcast-uri care o să vă
          capteze atenția, dar și multe alte surprize. Dacă ești student al Facultății de Automatică
          și Calculatoare și îți dorești o experiență unică la cele mai importante instituții
          culturale, atunci te invităm să îți creezi un cont:
          <Link className="registerLink" to="/register">
            înregistrează-te
          </Link>
          cu adresa de mail oferită de facultate (@stud.acs.upb.ro).
        </p>
        <div className="aboutButton">
          <MoftButton text={'REZERVĂ BILETE'} onClick={() => nav('/tickets')} type="button" />
        </div>
      </div>
    </div>
  );
}
