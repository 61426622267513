import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import { ISponsor } from '../../../../common/interfaces/api/sponsors.interface';
import { getSponsors } from '../../../../common/services/api/sponsors';
import PartnerItem from './PartnerItem';
import CarouselComponent from './Carousel/Carousel';

interface PartnerListProps {
  title: string;
}
export default function PartnerList(props: PartnerListProps) {
  const errorMessage = `„Ai puțintică răbdare!”<br/>&nbsp &nbsp &nbsp &nbsp &nbsp &nbsp—Trahanache`;

  const [sponsors, setSponsors] = useState<ISponsor[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getSponsors('institution')
      .then(response => {
        setSponsors(response.data);

        setIsError(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <div>
        <h1 className="mt-1">{props.title}</h1>
      </div>
      {!isLoading ? (
        !isError && sponsors.length !== 0 ? (
          <CarouselComponent sponsors={sponsors} itemsPerSlide={6} />
        ) : (
          <ErrorMessage message={errorMessage} customClass="background-black" />
        )
      ) : (
        <Spinner style={{ margin: 'auto', marginTop: '' }} animation="border" />
      )}
    </>
  );
}
