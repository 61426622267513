import Page from '../../common/components/Page/Page';
import ForgotPasswordForm from './components/ForgotPasswordForm';

export default function ForgotPassword() {
  const cardClasses = 'col-8 col-sm-10 col-md-6 col-lg-5 col-xl-5 col-xxl-4';
  return (
    <Page>
      <div className="row align-items-center justify-content-center mt-5">
        <div className="row">
          <h1>Recuperare parolă</h1>
        </div>
        <div className="row d-flex justify-content-center">
          <div className={`${cardClasses} mt-md-3`}>
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    </Page>
  );
}
