import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import { ISponsor } from '../../../../common/interfaces/api/sponsors.interface';
import { getSponsors } from '../../../../common/services/api/sponsors';
import PartnerItem from './PartnerItem';

const sponsors: ISponsor[] = [];

export default function PartnerList(props: { title: string }) {
  const errorMessage = `„Ai puțintică răbdare!”<br/>&nbsp &nbsp &nbsp &nbsp &nbsp &nbsp—Trahanache`;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sponsors, setSponsors] = useState<ISponsor[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getSponsors('sponsor')
      .then(response => {
        console.log(response.data);
        setSponsors(response.data);

        setIsError(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <div>
        <h1 className="mt-1">{props.title}</h1>
      </div>
      {!isLoading ? (
        !isError && sponsors.length !== 0 ? (
          <div className="row m-1 justify-content-center p-3 background-transparent">
            {sponsors.map((sponsor: ISponsor) => (
              <PartnerItem
                key={sponsor.id}
                sponsor={sponsor}
                type="static"
                // id={sponsor.id}
              />
            ))}
          </div>
        ) : (
          <ErrorMessage message={errorMessage} customClass="background-black" />
        )
      ) : (
        <Spinner style={{ margin: 'auto', marginTop: '' }} animation="border" />
      )}
    </>
  );
}
