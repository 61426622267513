import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  localStorage.removeItem('token');
  const nav = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token');
    nav('/login');
  }, []);

  return null;
}
