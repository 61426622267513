import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { verify } from '../../common/services/api/auth';

export default function Verify() {
  const nav = useNavigate();
  const { token } = useParams();

  // useEffect(() => {
  //   if (token) {
  //     verify(token).then(() => {
  //       nav('/login');
  //     });
  //   }
  //   nav('/login');
  // }, []);
  //account was verified, navigate to login page now
  useEffect(() => {
    if (token) {
      verify(token).then(() => {
        setTimeout(() => {
          nav('/login');
        }, 3000);
      });
    }
  }, []);

  return <h1>Veți fi redirecționat în curând la pagina de Autentificare!</h1>;
}
