import { useState } from 'react';
import MoftButton from '../../../../common/components/MoftButton/MoftButton';
import { IPodcast } from '../../../../common/interfaces/api/podcats.interface';
import styles from './PodcastItem.module.scss';
import { PodcastModal } from './PodcastModal';

import PlaceholderImage from '../../../../common/assets/podcast-placeholder.png';

export default function PodcastItem(props: IPodcast) {
  const cardClasses = 'col-sm-12 col-md-6 col-xxl-6 card border-0 bg-transparent';
  const { name, startDate, imgUrl, link } = props; //aici e eroareaaaaaaaaaaa

  const [date, setDate] = useState<Date>(new Date(startDate));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className={`${cardClasses} ${styles.podcastCard}`}>
      <div className={`row g-0 p-3 ${styles.podcastCardContainer}`}>
        <div className={`col-4 p-1 ${styles.imgContainer}`}>
          {imgUrl ? (
            <img src={imgUrl} className="img-fluid mh-50" alt="..." />
          ) : (
            <img
              src={PlaceholderImage}
              className={`img-fluid ${styles.imgFluidPodcast}`}
              alt="..."
            />
          )}
        </div>
        <div
          className={`col-8 d-flex align-items-center align-self-center ${styles.cardContainer}`}
        >
          <div className="card-body p-0">
            <p className={`card-title m-0 h5 ${styles.title}`}>{name}</p>
            {!isPodcastOld(new Date(startDate)) ? (
              <p className={`card-text h5 ${styles.description}`}>
                {date.toUTCString().substring(0, date.toUTCString().length - 7)}
              </p>
            ) : (
              <></>
            )}
            <MoftButton
              text={link ? 'Vizionează' : 'Detalii'}
              onClick={handleShow}
              type={'button'}
            />
            <PodcastModal podcast={props} onHide={handleClose} show={show} />
          </div>
        </div>
      </div>
    </div>
  );
}

function isPodcastOld(startDate: Date) {
  const presentDate = new Date();
  startDate.setHours(23, 59, 59);

  if (presentDate > startDate) return true;

  return false;
}
