import Page from '../../common/components/Page/Page';
import RegisterForm from './components/RegisterForm';
import handIMG from './components/ilustrationIMGcopy.png';
import './components/Register.scss';

export default function Register() {
  const cardClasses1 =
    'col-10 mb-sm-5 col-sm-9 col-md-6 col-lg-5 col-xl-5 col-xxl-4 card border-0 bg-transparent';
  return (
    <Page>
      <div className="row mb-4 align-items-center justify-content-center offset">
        <div className={`${cardClasses1} imgContainer`}>
          <img src={handIMG}></img>
        </div>
        <div className=" col-9 col-sm-10 col-md-5 col-lg-5 col-xl-5 col-xxl-4  offset-lg-1 text-center py-5 ">
          <RegisterForm />
        </div>
      </div>
    </Page>
  );
}
