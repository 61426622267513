import { reset } from '../../../common/services/api/auth';
import * as Yup from 'yup';
import { IUserReset } from '../../../common/interfaces/api/auth.interface';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { REQUIRED } from '../../../common/constants';
import { CustomForm } from '../../../common/components/MoftForm/CustomForm';

export default function ForgotPasswordForm() {
  const nav = useNavigate();
  const { token } = useParams();

  return (
    <>
      <CustomForm
        fields={[
          {
            name: 'password',
            initialValue: '',
            type: 'password',
            placeholder: 'Parola',
            validation: Yup.string()
              .min(8, 'Introdu o parolă de minim 8 caractere')
              .max(255, 'Introdu o parolă de maxim 8 caractere')
              .required(REQUIRED),
          },
          {
            name: 'password_confirmation',
            initialValue: '',
            type: 'password',
            placeholder: 'Confirmare parola',
            validation: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Parolele nu se potrivesc')
              .required(REQUIRED),
          },
        ]}
        submitButtonText="Trimite"
        onSubmit={async (values: IUserReset) => {
          try {
            if (token) {
              values.token = token;
            }
            await reset(values);
            // success message

            const response = 'Parola a fost schimbată cu succes';
            await Swal.fire({
              title: response,
              text: `Vei fi redirectat automat pe pagina de login`,
              icon: 'success',
              timer: 5000,
              showConfirmButton: true,
              confirmButtonText: 'Du-mă pe pagina de login',
            }).then(() => {
              nav('/login');
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (e: any) {
            // Error message
            const response = e.response.data.token;
            await Swal.fire({
              title: response,
              icon: 'error',
              timer: 5000,
              showConfirmButton: false,
            });

            throw e;
          }
        }}
      />
    </>
  );
}
